<template>
    <div>
        <div v-if="$vuetify.breakpoint.xs" class="text-center">
            <v-menu offset-y :close-on-content-click="false" class="ma-0 mr-2">
                <template v-slot:activator="{ on, attrs }">

                    <v-btn v-bind="attrs"
                           v-on="on"
                           ><v-icon color="primary">mdi-cog</v-icon>
                        الادارة
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item>
                        <v-list-item-title>
                            <router-link to="/dashboard/posts" class="text-decoration-none d-flex align-self-center">
                                <v-icon>mdi-earth</v-icon>
                                <span class="mr-2">المنشورات</span>
                            </router-link>
                        </v-list-item-title>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item>
                        <v-list-item-title>
                            <router-link to="/dashboard/jobs" class="text-decoration-none d-flex align-self-center">
                                <v-icon>mdi-briefcase</v-icon>
                                <span class="mr-2">الوظائف</span>
                            </router-link>
                        </v-list-item-title>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item>
                        <v-list-item-title>
                            <router-link to="/dashboard/projects" class="text-decoration-none d-flex align-self-center">
                                <v-icon>mdi-finance</v-icon>
                                <span class="mr-2">المشاريع</span>
                            </router-link>
                        </v-list-item-title>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item>
                        <v-list-item-title>
                            <router-link to="/dashboard/partnership" class="text-decoration-none d-flex align-self-center">
                                <v-icon>mdi-arrow-top-right-thick</v-icon>
                                <span class="mr-2">الشراكات</span>
                            </router-link>
                        </v-list-item-title>
                    </v-list-item>
                    <v-divider/>

                    <v-list-item>
                        <v-list-item-title>
                            <router-link to="/dashboard/about-us" class="text-decoration-none d-flex align-self-center">
                                <v-icon>mdi-information</v-icon>
                                <span class="mr-2">من نحن</span>
                            </router-link>
                        </v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                        <v-list-item-title>
                            <router-link to="/dashboard/contact-us" class="text-decoration-none d-flex align-self-center">
                                <v-icon>mdi-phone</v-icon>
                                <span class="mr-2">اتصل بنا</span>
                            </router-link>
                        </v-list-item-title>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item>
                        <v-list-item-title>
                            <router-link to="/dashboard/users" class="text-decoration-none d-flex align-self-center">
                                <v-icon>mdi-account</v-icon>
                                <span class="mr-2">المشرفين</span>
                            </router-link>
                        </v-list-item-title>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item @click="logout">
                        <v-list-item-title>
                                <v-icon>mdi-exit-run</v-icon>
                                <span class="mr-2">خروج</span>
                        </v-list-item-title>
                    </v-list-item>

                </v-list>
            </v-menu>
        </div>
        <v-toolbar color="primary"  v-if="!$vuetify.breakpoint.xs" width="100%">
            <v-toolbar-items class="d-flex">
                <router-link  to="/dashboard/posts" class="text-decoration-none d-flex align-self-center tool-nav">
                    <v-icon dark>mdi-earth</v-icon>
                    <span class="mr-2">المنشورات</span>
                </router-link>
                <router-link  to="/dashboard/jobs" class="text-decoration-none d-flex align-self-center tool-nav">
                    <v-icon dark>mdi-briefcase</v-icon>
                    <span class="mr-2">الوظائف</span>
                </router-link>
                <router-link  to="/dashboard/projects" class="text-decoration-none d-flex align-self-center tool-nav">
                    <v-icon dark>mdi-finance</v-icon>
                    <span class="mr-2">المشاريع</span>
                </router-link>
                <router-link  to="/dashboard/partnership" class="text-decoration-none d-flex align-self-center tool-nav">
                    <v-icon dark>mdi-arrow-top-right-thick</v-icon>
                    <span class="mr-2">الشراكات</span>
                </router-link>

                <router-link  v-if="!$vuetify.breakpoint.xs"  to="/dashboard/about-us" class="text-decoration-none d-flex align-self-center tool-nav">
                    <v-icon dark>mdi-information</v-icon>
                    <span class="mr-2">حولنا</span>
                </router-link>
                <router-link  v-if="!$vuetify.breakpoint.xs"  to="/dashboard/contact-us" class="text-decoration-none d-flex align-self-center tool-nav">
                    <v-icon dark>mdi-phone</v-icon>
                    <span class="mr-2">اتصل بنا</span>
                </router-link>
                <router-link  v-if="!$vuetify.breakpoint.xs"  to="/dashboard/users" class="text-decoration-none d-flex align-self-center tool-nav">
                    <v-icon dark>mdi-lock</v-icon>
                    <span class="mr-2">المشرفين </span>
                </router-link>
                <div style="cursor: pointer" @click="logout" text to="/dashboard/posts" class="text-decoration-none d-flex align-self-center tool-nav">
                    <v-icon dark>mdi-exit-run</v-icon>
                    <span class="mr-2">خروج </span>
                </div>


            </v-toolbar-items>
        </v-toolbar>
    </div>

</template>

<script>

    export default {
        name: "ToolBar",
        data(){
            return{
                user_owner:this.$store.state.dashboard.user.user_level
            }
        },
        methods:{
            async logout()
            {
                this.$axios.get('api/logout').then(res=>{
                    this.$store.state.user = [];
                    localStorage.clear();
                    this.$router.push('/dashboard/login');
                })
            }
        },
        computed:{
            get_level:function () {
                return this.$store.state.dashboard.user.user_level;
            }
        },
        watch:{
            get_level:function (new_level) {
                this.user_owner = new_level;
            }
        },
        created(){
            console.log(this.$store.state.dashboard.user.user_level)
        }

    }
</script>

<style scoped>
.tool-nav{
    color: white;;
    font-size: 18px;
    margin:5px;
    margin-right: 20px;
}
</style>