<template>
    <v-container>
        <v-dialog max-width="600" v-model="$store.state.dashboard.posts.forms.edit_post">
          <v-form lazy-validation v-model="valid" ref="form">
              <v-card>
                  <v-card-title class="primary">
                      <v-icon dark>mdi-pen</v-icon>
                      <span style="color: white">تعديل منشور</span>
                      <v-spacer/>
                      <v-btn icon @click="$store.state.dashboard.posts.forms.edit_post=false" dark><v-icon>mdi-close</v-icon></v-btn>

                  </v-card-title>
                  <v-divider/>
                  <v-card-text>
                      <v-row justify="center" class="mt-4">
                          <v-col cols="12">
                              <v-text-field :rules="req" outlined v-model="post.post_title" label="عنوان المنشور"/>
                          </v-col>
                            <v-col cols="12">
                                <v-text-field :rules="req" outlined v-model="post.post_title_en" label="عنوان المنشور في الانجليزية"/>                          </v-col>

                          <v-col cols="12">
                              <span>صورة السلايدر</span>
                              <v-sheet outlined class="d-flex" >

                                  <v-img contain @click="$refs.file.click()" :src="splash" min-width="300" min-height="250"  class="d-flex align-self-center" />
                                  <input @change="onFileChange" ref="file" type="file" class="d-none">
                              </v-sheet>
                              <v-text-field :rules="req" readonly v-model="splash"/>
                          </v-col>
                          <v-col cols="12">
                              <v-switch v-model="post.is_slide" label="عرض في السلايدر"/>
                          </v-col>
                          <v-col cols="12">
                          <span class="mx-2">
                              صور للمنشور
                          </span>
                              <v-btn @click="$refs.images.click()" fab x-small color="success"><v-icon dark>mdi-plus</v-icon></v-btn>
                              <input multiple="multiple"  @change="add_image_to_images" ref="images" type="file" class="d-none">
                              <v-list class="mt-4">
                                  <div v-for="(img,i) in images" :key="'i_e_'+i" >
                                      <v-list-item dense>
                                          <v-row>
                                              <v-col class="d-flex" cols="5">
                                                  <v-img class="d-flex align-self-center" :src="img.src == null ? $axios.defaults.baseURL+img.image_name:img.src" max-width="100" max-height="80" contain />
                                              </v-col>
                                              <v-col cols="5">
                                                  <span class="d-flex">{{img.name}}</span>
                                              </v-col>

                                          </v-row>

                                      </v-list-item>
                                      <v-divider/>
                                  </div>
                              </v-list>
                          </v-col>
                          <v-col cols="12">
                              <v-textarea :rules="req" outlined v-model="post.post_text" label="نص المنشور"/>
                          </v-col>
                          <v-col cols="12">
                              <v-textarea :rules="req" outlined v-model="post.post_text_en" label="نص المنشور في الانجليزية"/>
                          </v-col>

                          <v-col cols="12">
                              <v-btn block class="primary" :loading="loading" @click="upload">تعديل</v-btn>
                          </v-col>

                      </v-row>
                  </v-card-text>
              </v-card>
          </v-form>
        </v-dialog>
    </v-container>
</template>

<script>
    export default {
        name: "AddPost",
        data(){
            return{
                valid:true,
                loading:false,
                req:[v=>!!v || 'هذا الحقل مطلوب'] ,
                post:{
                    post_id:'',
                    post_title:'',
                    post_title_en:'',
                    post_text:'',
                    post_text_en:'',
                    post_image:'',
                    post_active:1,
                    is_slide:1

                },
                images:[],
                images_files:[],
                splash:'',
                file_splash:''
            }
        },
        methods:{
            onFileChange(e)
            {
                var file = e.target.files[0];
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.splash = e.target.result;
                }
                reader.readAsDataURL(file);
                this.file_splash = file;
            },

            add_image_to_images(e)
            {
                var selectedFiles = e.target.files;
                for (var i=0; i < selectedFiles.length; i++){
                    this.images_files.push(selectedFiles[i]);
                }

                for (var i2=0; i2<this.images_files.length; i2++){
                    let reader = new FileReader(); //instantiate a new file reader
                    reader.addEventListener('load', function(){
                       // this.$refs['image' + parseInt( i )][0].src = reader.result;
                        var element = {};
                        element.src = reader.result;
                        element.name = reader.name;
                        this.images.push(element)
                    }.bind(this), false);  //add event listener

                    reader.readAsDataURL(this.images_files[i2]);
                }

            },
            remove_img(index)
            {
                this.images.splice(index,1);
                this.images_files.splice(index,1);
                //this.$refs.images.files.splice(index,1);
            },

            async upload() {
                if(this.$refs.form.validate())
                {

                    this.loading = true;
                    var fd = new FormData();
                    fd.append('post_id',this.post.post_id);
                    fd.append('post_title',this.post.post_title);
                    fd.append('post_title_en',this.post.post_title_en);
                    fd.append('post_text',this.post.post_text);
                    fd.append('post_text_en',this.post.post_text_en);
                    fd.append('post_active',Number(this.post.post_active));
                    fd.append('post_splash',this.file_splash);
                    fd.append('is_slide',Number(this.post.is_slide));
                    //fd.append('images',this.images_files);
                    fd.append('user_id_fk',localStorage.getItem('user_id'));
                    for( var i = 0; i < this.images_files.length; i++ ){
                        let file = this.images_files[i];
                        fd.append('files[' + i + ']', file);
                    }

                    await this.$axios.post('api/edit-post',fd, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }}).then(res=>{
                        this.$store.commit("GET_POSTS");
                        this.$fire({
                            title: "نجح",
                            text: res.data.msg,
                            type: "success",
                            timer: 3000
                        });
                        this.$store.state.dashboard.posts.forms.edit_post=false;
                        this.$refs.form.reset();
                    }).catch(err=>{
                        console.log(err.response.data)
                    }).finally(fin=>{
                        this.loading = false;
                    })
                }
            },
            async get_post_to_edit()
            {
                this.$axios.post('api/get-post-to-edit',{post_id:this.post.post_id}).then(res=>{
                    this.post = res.data.post[0];
                    this.splash = this.$axios.defaults.baseURL  +res.data.post[0].post_splash;
                    this.images = res.data.images

                })
            },

        },
        computed:{
            get_post:function () {
                return this.$store.state.dashboard.posts.target_post;
            }
        },
        watch:{
            get_post:function (new_post) {
               this.post.post_id = new_post.post_id;
                this.get_post_to_edit();
            }
        },
        created()
        {

        }

    }
</script>

<style scoped>

</style>