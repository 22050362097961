<template>
    <v-card height="50">
        <v-footer
                v-bind="localAttrs"
                :padless="padless"
                class="pa-0"
        >
            <v-card
                    flat
                    tile
                    width="100%"
                    class="text-center"
                    color="#2aa623"
                    dark
            >
<!--                <v-card-text>-->
<!--                    <v-btn :href="'tel:'+$store.state.clients.settings.settings.phone1" class="mx-4" icon>-->
<!--                        <v-icon size="24px">mdi-phone</v-icon>-->
<!--                    </v-btn>-->
<!--                    <v-btn :href="'mailto:'+$store.state.clients.settings.settings.email" class="mx-4" icon>-->
<!--                        <v-icon size="24px">mdi-email</v-icon>-->
<!--                    </v-btn>-->
<!--                    <v-btn :href="'https://'+$store.state.clients.settings.settings.facebook" class="mx-4" icon>-->
<!--                        <v-icon size="24px">mdi-facebook</v-icon>-->
<!--                    </v-btn>-->
<!--                </v-card-text>-->

                <v-divider></v-divider>

                <v-card-text v-if="!$store.state.en" class="white--text">
                    <strong>جميع الحقوق محفوظة لشركة
                        <a href="https://pasma4tech.com/" target="_blank" class="text-decoration-none font-weight-bold" style="color: #6cd1ff">البصمة</a>
                        للحلول البرمجية</strong>
                    {{ new Date().getFullYear() }}
                </v-card-text>
                <v-card-text v-if="$store.state.en" class="white--text">
                    <strong>Copyright
                        <a href="https://pasma4tech.com/" target="_blank" class="text-decoration-none font-weight-bold" style="color: #6cd1ff">PASMA</a>
                        for software solutions
                         </strong>
                    {{ new Date().getFullYear() }}
                </v-card-text>


            </v-card>
        </v-footer>
    </v-card>
</template>

<script>
    export default {
        name: "Footer",
        data() {
            return {
                icons: [
                    'mdi-home',
                    'mdi-email',
                    'mdi-phone',
                    'mdi-facebook',
                    'mdi-map-marker',
                ],
                items: [
                    'default',
                    'absolute',
                    'fixed',
                ],
                padless: false,
                variant: 'default',
                picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOStrin
            }
        },
        computed: {
            localAttrs() {
                const attrs = {}

                if (this.variant === 'default') {
                    attrs.absolute = false
                    attrs.fixed = false
                } else {
                    attrs[this.variant] = true
                }
                return attrs
            },
        },
    }
</script>

<style scoped>

</style>