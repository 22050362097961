<template>
    <v-container>
        <v-dialog max-width="500" v-model="$store.state.dashboard.users.forms.edit_user">
        <v-form lazy-validation v-model="valid" ref="form">
            <v-card>
                <v-card-title class="primary">
                    <v-icon dark>mdi-pen</v-icon>
                    <span class="mr-2" style="color: white">تعديل مشرف</span>
                    <v-spacer/>
                    <v-btn icon dark @click="$store.state.dashboard.users.forms.edit_user=false"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-divider/>
                <v-card-text>
                    <v-row justify="center">
                        <v-col cols="12">
                            <v-text-field :rules="req" outlined label="اسم المشرف" v-model="user.name"/>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field outlined label="كلمة المرور : بدون تغيير" v-model="user.password"/>
                        </v-col>
                        <v-col cols="12">
                            <v-select :rules="req" :items="levels" item-text="label" item-value="value" outlined label="الصلاحية" v-model="user.user_level"/>
                        </v-col>

                    </v-row>
                </v-card-text>
                <v-divider/>
                <v-card-actions>
                    <v-btn color="primary" @click="edit_user" :loading="loading">حفظ</v-btn>
                    <v-btn outlined @click="$store.state.dashboard.users.forms.edit_user=false">اللغاء</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
        </v-dialog>
    </v-container>
</template>

<script>
    export default {
        name: "EditUser",
        data(){
            return{
                user:{
                    name:'',
                    password:'',
                    user_level:1,
                },
                levels:[
                    {label:'مدير',value:1},
                    {label:'محرر',value:2},
                ],
                loading:false,
                valid:true,
                req:[v=>!!v || 'هذا الحقل مطلوب'],
                req_pass:[v=>!!v && v.length>5 || 'هذا الحقل مطلوب 6 أحرف او ارقام']
            }
        },
        methods:{
            async edit_user(){
               if(this.$refs.form.validate())
               {
                   await this.$axios.post('api/edit-user',this.user).then(res=>{
                       this.$store.commit("GET_USERS");
                       this.$store.state.dashboard.users.forms.edit_user=false;
                       this.$fire({
                           title: "نجح",
                           text: res.data.msg,
                           type: "success",
                           timer: 3000
                       });
                   }).catch(err=>{
                       console.log(err.response.data)
                      var data = JSON.stringify(err.response.data);

                           this.$fire({
                               title: "عفوا",
                               text: data,
                               type: "warning",
                               timer: 3000
                           });

                   }).finally(fin=>{
                       this.loading = false
                   })
               }
            }
        },
        computed:{
            get_user_to_edit:function () {
                return this.$store.state.dashboard.users.forms.edit_user;
            }
        },
        watch:{
            get_user_to_edit:function (new_form) {
                if(new_form)
                {
                    this.user = this.$store.state.dashboard.users.target;
                    this.user.password = '';
                }
            }
        },

    }
</script>

<style scoped>

</style>