import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from "@/router";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    style:false,
    en:false,
    dashboard:{
      user:{
        name: "",
        token: "",
        user_id: "",
        user_level: "",
        email: ""
      },
      posts:{
        posts:[],
        target_post:[],
        forms:{
          add_post:false,
          edit_post:false,
          delete:false,
        }
      },
      users:{
        users:[],
        target:[],
        forms:{
          add_user:false,
          edit_user:false,
          delete_user:false
        }
      },
      jobs:{
        jobs:[],
        target_job:[],
        forms:{
          add_job:false,
          edit_job:false,
          delete_job:false
        }
      },
      projects:{
        projects:[],
        target_project:[],
        forms:{
          add_project:false,
          edit_project:false,
          delete_project:false
        }
      }
    },
    clients:{
      news:{
        news:[],
      },
      slides:{
        slides:[]
      },
      settings:{
        settings:{
          phone1:'',
          phone2:'',
          email:'',
          faceboock:'',
          address:''
        }
      },
      jobs:{
        jobs:[]
      },
      projects:{
        projects:[]
      }
    }
  },
  mutations: {
    GET_POSTS(state)
    {
      axios.get('api/get-posts').then(res=>{
        state.dashboard.posts.posts = res.data;
      })
    },
    GET_USERS(state)
    {
      axios.get('api/get-users').then(res=>{
        state.dashboard.users.users = res.data;
      })
    },

    /////////////////////////
    RENDER(state)
    {
      axios.get('api/render').then(res=>{
        state.clients.news.news = res.data.news;
        state.clients.slides.slides = res.data.slides;
        state.clients.settings.settings = res.data.settings;
      }).catch(err=>{
        console.log(err)
      })
    },
    GET_NEWS(state)
    {
      axios.get('api/get-news').then(res=>{
        state.clients.news.news = res.data;
      }).catch(err=>{
        console.log(err)
      })
    },
    GET_SLIDES(state)
    {
      axios.get('api/get-slides').then(res=>{
        state.clients.slides.slides = res.data;
      }).catch(err=>{
        console.log(err)
      })
    },
    GET_SETTINGS(state)
    {
      axios.get('api/get-settings').then(res=>{
        state.clients.settings.settings = res.data;
      }).catch(err=>{
        console.log(err)
      })
    },
    GET_JOBS(state)
    {
      axios.get('api/get-jobs').then(res=>{
        state.dashboard.jobs.jobs = res.data;
      }).catch(err=>{
        console.log(err)
        if(err.response.status == 401)
        {
          router.push('/dashboard/login')
        }
      })
    },
    GET_PROJECTS(state)
    {
      axios.get('api/get-projects').then(res=>{
        state.dashboard.projects.projects = res.data;
      }).catch(err=>{
        console.log(err)
        if(err.response.status == 401)
        {
          router.push('/dashboard/login')
        }
      })
    },
    GET_JOBS_C(state)
    {
      axios.get('api/get-jobs').then(res=>{
        state.clients.jobs.jobs = res.data;
      }).catch(err=>{
        console.log(err)

      })
    },
    GET_PROJECTS_C(state)
    {
      axios.get('api/get-projects').then(res=>{
        state.clients.projects.projects = res.data;
      }).catch(err=>{
        console.log(err)

      })
    },

  },
  actions: {
  },
  modules: {
  }
})
