<template>
    <v-card class="mt-16 mx-4">
        <v-card-title>
            <h2 v-if="!$store.state.en">معرض الصور</h2>
            <h2 v-if="$store.state.en">Gallery</h2>
        </v-card-title>
        <v-divider/>
        <v-card-text>
            <v-row>

                <v-col
                        v-for="(item , i) in pageOfItems"
                        :key="'img_gallery_'+i"
                        class="d-flex child-flex"
                        cols="12"
                        md="6"
                        lg="4"
                        xl="3"
                >
                    <a :href="$axios.defaults.baseURL+item.image_name">
                        <v-img
                                :max-height="$vuetify.breakpoint.xs?200:300"
                                :src="$axios.defaults.baseURL+item.image_name"
                                :lazy-src="`https://picsum.photos/10/6?image=${n * 5 + 10}`"
                                aspect-ratio="1"
                                class="grey lighten-2"
                        >
                            <template v-slot:placeholder>
                                <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                >
                                    <v-progress-circular
                                            indeterminate
                                            color="grey lighten-5"
                                    ></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>
                    </a>
                </v-col>
            </v-row>
        </v-card-text>
        <v-divider/>
        <v-card elevation="1" class="f14 text-center pa-4">
            <jw-pagination :pageSize="15" :maxPages="$vuetify.breakpoint.xs?1:10"  :items="images" :labels="customLabels" :styles="customStyles" @changePage="onChangePage"></jw-pagination>
        </v-card>
    </v-card>


</template>

<script>
    import JwPagination from 'jw-vue-pagination';
    const customLabels = {
        first: 'الاول',
        last: 'الاخير',
        previous: 'السابق',
        next: 'التالي'
    };
    const customStyles = {
        ul: {
            direction:'ltr'
        },
        li: {
            display: 'inline-block',
            border:'1px solid grey',
            margin:'2px',
            borderRadius:'5px'
        },
        a: {
            color: 'black'
        }
    };
    export default {
        name: "Gallery",
        components:{
            JwPagination,
        },
        data(){
            return{
                images:[],
                customLabels,
                customStyles,
                pageOfItems: [],
            }

        },
        methods: {
            onChangePage(pageOfItems) {
                // update page of items
                this.pageOfItems = pageOfItems;
            },
        },
        created(){
            this.$axios.get('api/get-images').then(res=>{
                this.images = res.data
            })
        }
    }
</script>

<style scoped>

</style>