<template>
    <v-container>
        <v-row justify="center">
            <v-col>
                <v-card class="mt-16" elevation="8">
                    <v-card-title>
                        <h3 v-if="!$store.state.en">وظيفة</h3>
                        <h3 v-if="$store.state.en">JOB</h3>
                    </v-card-title>
                    <v-divider/>
                    <v-card-title>
                        <v-icon class="ma-auto" size="150" color="primary">mdi-briefcase</v-icon>
                    </v-card-title>

                    <v-divider/>

                    <v-card-title  style="color: white" class="primary">
                        <h5 class="ma-auto" v-if="!$store.state.en">عنوان الوظيفة</h5>
                        <h5 class="ma-auto"  v-if="$store.state.en">Job Title</h5>
                        <span v-if="!$store.state.en" class="ma-auto" >{{job.job_title}}</span>
                        <span v-if="$store.state.en" class="ma-auto">{{job.job_title_en}}</span>
                    </v-card-title>
                    <v-divider/>
                    <v-card-title>
                        <h5 class="ma-auto" v-if="!$store.state.en">تاريخ نشر الوظيفة</h5>
                        <h5 class="ma-auto"  v-if="$store.state.en">Post Date</h5>
                        <span class="ma-auto">{{job.job_date.substr(0,10)}}</span>
                    </v-card-title>
                    <v-divider/>
                    <v-card-text>
                                <div v-if="!$store.state.en" class="text-center  ma-auto" style="width: 100%">
                                    <h5 class=" ma-0 ma-auto pa-4  " v-if="!$store.state.en">تفاصيل الوظيفة</h5>
                                    <v-divider/>
                                    <p class="text-justify ma-auto pa-4">
                                        {{job.job_desc}}
                                    </p>

                                </div>
                                <div  v-if="$store.state.en" class="text-justify ">
                                    <h5 class="ma-auto" v-if="!$store.state.en">Job Description</h5>
                                    <div class="text-justify ma-auto pa-4">
                                        {{job.job_desc_en}}
                                    </div>
                                </div>

                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "ReadNew",
        data(){
            return{
                job:{
                    job_title:'',
                    job_title_en:'',
                    job_desc:'',
                    job_desc_en:'',
                    job_date:''
                },
            }
        },
        methods:{
            get_the_job()
            {
                var id = this.$route.path.split('read/')[1];
                this.$axios.get('api/jobs/read/'+id).then(res=>{
                    this.job = res.data.job;
                })
            }
        },


        created(){
            this.get_the_job()
        }
    }
</script>

<style scoped>

</style>