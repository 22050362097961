<template>
    <v-container>
        <v-card>
                <ToolBar/>
            <v-divider/>
            <v-card-title>
                <v-icon>mdi-phone</v-icon>
                <span>اتصل بنا</span>
            </v-card-title>
            <v-divider/>
            <v-card-text>
                <v-text-field v-model="contact.phone1" outlined label="رقم الهاتف الاول"/>
                <v-text-field v-model="contact.phone2" outlined label="رقم الهاتف الثاني"/>
                <v-text-field v-model="contact.email" outlined label="البريد الالكتروني"/>
                <v-text-field v-model="contact.address" outlined label="العنوان"/>
                <v-text-field v-model="contact.facebook" outlined label="صفحة الفيسبوك"/>
            </v-card-text>
            <v-divider/>
            <v-card-actions>
                <v-btn @click="save" block color="primary" :loading="loading" dark>حفظ</v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
    import ToolBar from "@/views/Dashboard/ToolBar";
    export default {
        name: "ContactUs",
        data(){
            return{
                loading:false,
                contact:{
                    phone1:'',
                    phone2:'',
                    email:'',
                    address:'',
                    facebook:''
                }
            }
        },
        components:{
            ToolBar
        },
        methods:{
            async save()
            {
                this.loading = true;
                await this.$axios.post('api/save-contact-us',this.contact).then(res=>{
                    this.$fire({
                        title: "نجح",
                        text: res.data.msg,
                        type: "success",
                        timer: 3000
                    });
                }).catch(err=>{
                    var data = JSON.stringify(err.response.data);

                    this.$fire({
                        title: "عفوا",
                        text: data,
                        type: "warning",
                        timer: 3000
                    });

                }).finally(fin=>{
                    this.loading = false
                })
            },
            async get_contact()
            {
                await this.$axios.get('api/get-contact-us',this.contact).then(res=>{
                    this.contact = res.data;
                }).catch(err=>{
                    var data = JSON.stringify(err.response.data);

                    this.$fire({
                        title: "عفوا",
                        text: data,
                        type: "warning",
                        timer: 3000
                    });

                })
            }
        },
        created()
        {
            this.get_contact()
        }
    }
</script>

<style scoped>

</style>