<template>
    <v-dialog v-model="$store.state.dashboard.jobs.forms.add_job" max-width="800">
        <v-form lazy-validation v-model="valid" ref="form">
            <v-card>
                <v-card-title class="primary">
                    <div style="color: white">اضافة وظيفة</div>
                    <v-spacer/>
                    <v-btn icon dark @click="$store.state.dashboard.jobs.forms.add_job=false"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-divider/>
                <v-card-text class="mt-4">
                    <v-row justify="center">
                        <v-col cols="12">
                            <v-text-field v-model="job.job_title" :rules="req" outlined label="عنوان الوظيفة"/>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="job.job_title_en" :rules="req" outlined label="عنوان الوظيفة بالانجليزية"/>
                        </v-col>

                        <v-col cols="12">
                            <v-textarea v-model="job.job_desc" :rules="req" outlined label="تفاصيل الوظيفة"/>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea v-model="job.job_desc_en" :rules="req" outlined label="تفاصيل الوظيفة بالانجليزية"/>
                        </v-col>

                    </v-row>
                </v-card-text>
                <v-divider/>
                <v-card-actions>
                    <v-btn outlined @click="$store.state.dashboard.jobs.forms.add_job=false">اللغاء</v-btn>
                    <v-btn color="primary" class="mr-4" :loading="loading" @click="save_job">حفظ</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
    export default {
        name: "AddJob",
        data(){
            return{
                job:{
                    job_title:'',
                    job_title_en:'',
                    job_desc:'',
                    job_desc_en:''
                },
                req:[v=>!!v || 'هذا الحقل مطلوب'],
                valid:true,
                loading:false
            }
        },
        methods:{
            save_job()
            {
                if(this.$refs.form.validate())
                {
                    this.loading = true;

                    this.$axios.post('api/add-job',this.job).then(res=>{
                        this.$store.commit('GET_JOBS');
                        this.$store.state.dashboard.jobs.forms.add_job = false;
                        this.$fire({
                            title: "نجح",
                            text: res.data.msg,
                            type: "success",
                            timer: 3000
                        });
                    }).catch(err=>{
                        console.log(err)
                    }).finally(fin=>{
                        this.loading = false;
                    })
                }
            }
        }
    }
</script>

<style scoped>

</style>