<template>
    <v-container>
        <v-card >
                <ToolBar/>
            <v-divider/>
            <v-card-title>
                <v-icon>mdi-information</v-icon>
                <span v-if="!$store.state.en">معلومات عنا</span>
                <span v-if="$store.state.en">About Us</span>
            </v-card-title>
            <v-divider/>
            <v-card-text>
                <VueEditor v-model="about_us"/>
                <VueEditor class="mt-4" v-model="about_us_en"/>

            </v-card-text>
            <v-divider/>
            <v-card-actions>
                <v-btn :loading="loading" @click="save" max-width="400" block color="primary">
                    حقظ
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
    import ToolBar from "@/views/Dashboard/ToolBar";
    import { VueEditor } from "vue2-editor";
    export default {
        name: "AboutUs",
        components:{
            ToolBar,
            VueEditor
        },
        data(){
            return{
                about_us:'',
                about_us_en:'',
                loading:false
            }
        },
        methods:{
            async save()
            {
                this.loading = true;
                await this.$axios.post('api/save-about-us',{about_us:this.about_us,about_us_en:this.about_us_en}).then(res=>{
                    this.$fire({
                        title: "نجح",
                        text: res.data.msg,
                        type: "success",
                        timer: 3000
                    });
                }).catch(err=>{
                    var data = JSON.stringify(err.response.data);

                    this.$fire({
                        title: "عفوا",
                        text: data,
                        type: "warning",
                        timer: 3000
                    });

                }).finally(fin=>{
                    this.loading = false
                })
            },
            async get_about()
            {
                await this.$axios.get('api/get-about-us',this.about_us).then(res=>{
                    this.about_us = res.data.about_us;
                    this.about_us_en = res.data.about_us_en;
                }).catch(err=>{
                    var data = JSON.stringify(err.response.data);

                    this.$fire({
                        title: "عفوا",
                        text: data,
                        type: "warning",
                        timer: 3000
                    });

                })
            }
        },
        created(){
            this.get_about()
        }
    }
</script>

<style scoped>

</style>