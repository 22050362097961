<template>
    <v-container>
        <v-dialog v-model="$store.state.dashboard.jobs.forms.delete_job" max-width="500">
            <v-card>
                <v-card-title class="error">
                    <v-icon dark>mdi-delete</v-icon>
                    <span style="color: white" class="mr-2">حذف وظيفة</span>
                </v-card-title>
                <v-divider/>
                <v-card-texts>
                    <div class="text-center pa-8" style="width: 100%;">
                        <h3 >هل انت متأكد من حذف الوظيفة</h3>
                        <h3 >{{job.job_title}}</h3>
                    </div>
                </v-card-texts>
                <v-divider/>
                <v-card-actions>
                    <v-btn @click="delete_job" :loading="loading" color="primary">حذف</v-btn>
                    <v-btn outlined @click="$store.state.dashboard.jobs.forms.delete_job=false">اللغاء</v-btn>

                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    export default {
        name: "DeleteJob",
        data(){
            return{
                job:{
                    job_id:0,
                    job_title:'',
                    job_desc:''
                },
                loading:false
            }
        },
        methods:{
            async delete_job()
            {
                this.loading = true;
                await  this.$axios.post('api/delete-job',this.job).then(res=>{
                    this.$store.commit("GET_JOBS");
                    this.$store.state.dashboard.jobs.forms.delete_job=false;
                    this.$fire({
                        title: "نجح",
                        text: res.data.msg,
                        type: "success",
                        timer: 3000
                    });
                }).catch(err=>{
                    console.log(err.response.data)
                }).finally(fin=>{
                    this.loading = false
                })
            }
        },
        computed:{
            get_job:function () {
                return this.$store.state.dashboard.jobs.target_job;
            }
        },
        watch:{
            get_job:function (new_job) {
                this.job = new_job
            }
        },

    }
</script>

<style scoped>

</style>