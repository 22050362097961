<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12">
                <v-card class="ma-auto mt-12 " elevation="8" max-width="1000">
                    <v-card-title>
                        <h3 v-if="!$store.state.en">مشروع</h3>
                        <h3 v-if="$store.state.en">Project</h3>
                    </v-card-title>
                    <v-divider/>
                    <v-card-title class="text-center">
                        <div style="width: 100%">
                            <v-img  :src="axios.defaults.baseURL+project.project_image"></v-img>
                        </div>

                    </v-card-title>

                    <v-divider/>

                    <v-card-title  style="color: white" class="primary">
                        <h5 class="ma-auto" v-if="!$store.state.en">عنوان المشروع</h5>
                        <h5 class="ma-auto"  v-if="$store.state.en">Project Title</h5>
                        <span v-if="!$store.state.en" class="ma-auto" >{{project.project_title}}</span>
                        <span v-if="$store.state.en" class="ma-auto">{{project.project_title_en}}</span>
                    </v-card-title>
                    <v-divider/>
                    <v-card-text>
                                <div v-if="!$store.state.en" class="text-center ma-auto" style="width: 100%">
                                    <h4 class=" ma-0 ma-auto pa-4  " v-if="!$store.state.en">تفاصيل المشروع</h4>
                                    <v-divider/>
                                    <p class="text-justify ma-auto pa-4" style="font-size: 18px;line-height: 40px">
                                        {{project.project_desc}}
                                    </p>

                                </div>
                                <div  v-if="$store.state.en" class="text-justify">
                                    <h5 class="ma-auto pa-4" v-if="$store.state.en">Project Description</h5>
                                    <v-divider/>
                                    <div class="text-justify ma-auto pa-4">
                                        {{project.project_desc_en}}
                                    </div>
                                </div>

                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "ReadProject",
        data(){
            return{
                project:{
                    project_title:'',
                    project_title_en:'',
                    project_desc:'',
                    project_desc_en:'',
                    project_image:''
                },
            }
        },
        methods:{
            get_the_project()
            {
                var id = this.$route.path.split('read/')[1];
                this.$axios.get('api/projects/read/'+id).then(res=>{
                    this.project = res.data.project;
                })
            }
        },


        created(){
            this.get_the_project()
        }
    }
</script>

<style scoped>

</style>