<template>
    <v-container>
        <v-dialog v-model="$store.state.dashboard.users.forms.delete_user" max-width="500">
            <v-card>
                <v-card-title class="error">
                    <v-icon dark>mdi-delete</v-icon>
                    <span style="color: white" class="mr-2">حذف مشرف</span>
                </v-card-title>
                <v-divider/>
                <v-card-texts>
                    <div class="text-center pa-8" style="width: 100%;">
                        <h3 >هل انت متأكد من حذف المشرف</h3>
                        <h3 >{{user.name}}</h3>
                    </div>
                </v-card-texts>
                <v-divider/>
                <v-card-actions>
                    <v-btn @click="delete_user" :loading="loading" color="primary">حذف</v-btn>
                    <v-btn outlined @click="$store.state.dashboard.users.forms.delete_user=false">اللغاء</v-btn>

                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    export default {
        name: "DeleteUser",
        data(){
            return{
                loading:false,
                user:{
                    name:'',
                    user_id:''
                }
            }
        },
        methods:{
            async delete_user()
            {
                this.loading = true;
                await  this.$axios.post('api/delete-user',this.user).then(res=>{
                    this.$store.commit("GET_USERS");
                    this.$store.state.dashboard.users.forms.delete_user=false;
                    this.$fire({
                        title: "نجح",
                        text: res.data.msg,
                        type: "success",
                        timer: 3000
                    });
                }).catch(err=>{
                    console.log(err.response.data)
                }).finally(fin=>{
                    this.loading = false
                })
            }
        },
        computed:{
            get_user:function () {
                return this.$store.state.dashboard.users.target;
            }
        },
        watch:{
            get_user:function (new_user) {
                this.user = new_user
            }
        },

    }
</script>

<style scoped>

</style>