import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from "@/views/Login";
import MainDashboard from "@/views/Dashboard/MainDashboard";
import Posts from "@/views/Dashboard/Posts/Posts";
import News from "@/views/Client/News";
import ReadNew from "@/views/Client/ReadNew";
import Users from "@/views/Dashboard/Users/Users";
import AboutUs from "@/views/Dashboard/AboutUs/AboutUs";
import AboutUsClient from "@/views/Client/AboutUs";
import ContactUs from "@/views/Dashboard/ContactUs/ContactUs";
import Gallery from "@/views/Client/Gallery";
import Jobs from "@/views/Dashboard/Jobs/Jobs";
import JobsC from "@/views/Client/Jobs";

import Projects from "@/views/Dashboard/Projects/Projects";
import ReadJob from "@/views/Client/ReadJob";
import ProjectsC from "@/views/Client/ProjectsC";
import ReadProject from "@/views/Client/ReadProject";
import PartnershipC from "@/views/Client/Partnership";
import Partnership from "@/views/Dashboard/Partnership/Partnership";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about-us',
    name: 'AboutUsClient',
    component: AboutUsClient
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: Gallery
  },

  {
    path: '/dashboard/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/dashboard',
    name: 'MainDashboard',
    component: MainDashboard
  },
  {
    path: '/dashboard/posts',
    name: 'Posts',
    component: Posts
  },
  {
    path: '/dashboard/about-us',
    name: 'AboutUs',
    component: AboutUs
  },
  {
    path: '/dashboard/partnership',
    name: 'Partnership',
    component: Partnership
  },

  {
    path: '/dashboard/contact-us',
    name: 'ContactUs',
    component: ContactUs
  },

  {
    path: '/dashboard/users',
    name: 'Users',
    component: Users
  },
  {
    path: '/dashboard/jobs',
    name: 'Jobs',
    component: Jobs
  },
  {
    path: '/dashboard/projects',
    name: 'Projects',
    component: Projects
  },

  {
    path: '/news',
    name: 'News',
    component: News
  },
  {
    path: '/news/read/*',
    name: 'ReadNew',
    component: ReadNew
  },
  {
    path: '/jobs',
    name: 'JobsC',
    component: JobsC
  },
  {
    path: '/jobs/read/*',
    name: 'ReadJob',
    component: ReadJob
  },
  {
    path: '/projects',
    name: 'ProjectsC',
    component: ProjectsC
  },
  {
    path: '/projects/read/*',
    name: 'ReadProject',
    component: ReadProject
  },
  {
    path: '/partnership',
    name: 'Partnership',
    component: PartnershipC
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
