<template>
    <v-container>
        <v-card>
            <ToolBar/>
            <v-divider/>
            <v-card-title>
                <v-icon>mdi-finance</v-icon>
                <div class="f18 mr-2">المشاريع</div>
            </v-card-title>
            <v-divider/>
            <v-card-title>
                <v-btn fab small color="primary" @click="$store.state.dashboard.projects.forms.add_project=true">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider/>
            <v-card-text>
                <v-simple-table v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-center f18">ت</th>
                        <th class="text-center f18">العنوان</th>
                        <th class="text-center f18">خيارات</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item , i ) in pageOfItems" :key="'job__'+i">
                        <td class="text-center f18">{{projects.indexOf(item)+1}}</td>
                        <td class="text-center f18">{{item.project_title}}</td>
                        <td class="text-center f18">
                            <v-btn icon @click="set_project_to_edit(item)">
                                <v-icon color="primary">mdi-pen</v-icon>
                            </v-btn>
                            <v-btn icon @click="set_project_to_delete(item)">
                                <v-icon color="error">mdi-delete</v-icon>
                            </v-btn>

                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </v-card-text>
            <v-divider/>
            <v-card elevation="1" class="f14 text-center pa-4">
                <jw-pagination :pageSize="15" :maxPages="$vuetify.breakpoint.xs?1:10"  :items="projects" :labels="customLabels" :styles="customStyles" @changePage="onChangePage"></jw-pagination>
            </v-card>
        </v-card>
        <AddProject/>
        <EditProject/>
        <DeleteProject/>
    </v-container>
</template>

<script>
    import ToolBar from "@/views/Dashboard/ToolBar";
    import AddProject from "@/views/Dashboard/Projects/AddProject";
    import EditProject from "@/views/Dashboard/Projects/EditProject";
    import DeleteProject from "@/views/Dashboard/Projects/DeleteProject";
    import JwPagination from 'jw-vue-pagination';
    const customLabels = {
        first: 'الاول',
        last: 'الاخير',
        previous: 'السابق',
        next: 'التالي'
    };
    const customStyles = {
        ul: {
            direction:'ltr'
        },
        li: {
            display: 'inline-block',
            border:'1px solid grey',
            margin:'2px',
            borderRadius:'5px'
        },
        a: {
            color: 'black'
        }
    };
    export default {
        name: "Projects",
        components:{
          AddProject,
            DeleteProject,
            EditProject,
            JwPagination,
            ToolBar
        },
        data(){
            return{
                projects:this.$store.state.dashboard.projects.projects,
                customLabels,
                customStyles,
                pageOfItems: [],
            }
        },
        methods: {
            onChangePage(pageOfItems) {
                // update page of items
                this.pageOfItems = pageOfItems;
            },
            set_project_to_edit(proj)
            {
                this.$store.state.dashboard.projects.target_project = proj;
                this.$store.state.dashboard.projects.forms.edit_project = true;
            },
            set_project_to_delete(proj)
            {
                this.$store.state.dashboard.projects.target_project = proj;
                this.$store.state.dashboard.projects.forms.delete_project = true;
            },

        },
        computed:{
            get_projects:function () {
                return this.$store.state.dashboard.projects.projects;
            }
        },
        watch:{
            get_projects:function (new_projects) {
                this.projects = new_projects;
            }
        },
        created(){
            this.$store.commit("GET_PROJECTS");
        }
    }
</script>

<style scoped>
.f18{
    font-size: 16px !important;
}
</style>