<template>
    <v-container class="pa-4">
        <v-card elevation="0">
            <v-card-title>
                <div class="mt-8">
                    <h2 v-if="!$store.state.en">الاخبار</h2>
                    <h2 v-if="$store.state.en">News</h2>
                </div>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col v-for="item in pageOfItems" :key="'new_i_'+item.post_id" class="mt-4">
                        <router-link :to="'/news/read/'+item.post_id" class="text-decoration-none">
                            <v-card elevation="4">
                                <v-row>
                                    <v-col cols="12" md="3" class="pa-6">
                                        <div style="overflow: hidden;">
                                            <v-img class="img-new" contain width="100%" :src="axios.defaults.baseURL+item.post_splash"/>

                                        </div>
                                    </v-col>
                                    <v-col cols="12" md="9">
                                        <v-card elevation="0">
                                            <v-card-title>
                                                <h3 v-if="!$store.state.en">{{item.post_title}}</h3>
                                                <h3 v-if="$store.state.en">{{item.post_title_en}}</h3>
                                            </v-card-title>
                                            <v-divider/>
                                            <v-card-subtitle>{{item.post_date.substr(0,10)}}</v-card-subtitle>
                                            <v-card-text>
                                                <p v-if="!$store.state.en">
                                                    {{item.post_text}}
                                                </p>
                                                <p v-if="$store.state.en">
                                                    {{item.post_text_en}}
                                                </p>

                                            </v-card-text>

                                        </v-card>
                                    </v-col>
                                </v-row>

                            </v-card>
                        </router-link>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider/>
            <v-card elevation="1" class="f14 text-center pa-4">
                <jw-pagination :pageSize="15" :maxPages="$vuetify.breakpoint.xs?1:10"  :items="news" :labels="customLabels" :styles="customStyles" @changePage="onChangePage"></jw-pagination>
            </v-card>
        </v-card>



    </v-container>
</template>

<script>
    import JwPagination from 'jw-vue-pagination';
    const customLabels = {
        first: '<<',
        last: '>>',
        previous: '<',
        next: '>'
    };
    const customStyles = {
        ul: {
            direction:'ltr'
        },
        li: {
            display: 'inline-block',
            border:'1px solid grey',
            margin:'2px',
            borderRadius:'5px'
        },
        a: {
            color: 'black'
        }
    };
    export default {
        name: "News",
        components:{
            JwPagination,
        },
        data(){
            return{
                news:this.$store.state.clients.news.news,
                customLabels,
                customStyles,
                pageOfItems: [],
            }
        },
        methods: {
            onChangePage(pageOfItems) {
                // update page of items
                this.pageOfItems = pageOfItems;
            },
        },
        computed:{
            get_news:function () {
                return this.$store.state.clients.news.news
            }
        },
        watch:{
            get_news:function (new_news) {
                this.news = new_news;

            }
        },
        created(){
            this.$store.commit("GET_NEWS");
            console.log(this.news)
        }

    }
</script>

<style scoped>
    .img-new{
        transition: .2s linear;
    }
    .img-new:hover{
        transform: scale(1.1);
    }

</style>