<template>
    <v-container>
        <v-card class="mt-16">
            <v-card-title>
                <v-icon>mdi-arrow-top-right-thick</v-icon>
                <span v-if="!$store.state.en" class="mr-2 ml-2">الشراكات</span>
                <span v-if="$store.state.en" class="mr-2 ml-2">Partnerships</span>
            </v-card-title>
            <v-divider/>
            <v-card-text class="pa-4">
                <div style="width: 100%" v-if="!$store.state.en" v-html="partnership"></div>
                <div style="width: 100%" v-if="$store.state.en" v-html="partnership_en"></div>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
    export default {
        name: "Partnership",
        data(){
            return{
                partnership:'',
                partnership_en:'',
            }
        },
        created(){
            this.$axios.get('api/get-partnership').then(res=>{
                this.partnership = res.data.partnership;
                this.partnership_en = res.data.partnership_en;
            })
        }
    }
</script>

<style scoped>

</style>