<template>
    <v-container>
        <v-card>
            <ToolBar/>
            <v-divider/>
            <v-card-title>
                <v-icon>mdi-briefcase</v-icon>
                <div class="f18 mr-2">الوظائف</div>
            </v-card-title>
            <v-divider/>
            <v-card-title>
                <v-btn fab small color="primary" @click="$store.state.dashboard.jobs.forms.add_job=true">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider/>
            <v-card-text>
                <v-simple-table v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-center f18">ت</th>
                        <th class="text-center f18">العنوان</th>
                        <th class="text-center f18">تاريخ النشر</th>
                        <th class="text-center f18">خيارات</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item , i ) in pageOfItems" :key="'job__'+i">
                        <td class="text-center f18">{{jobs.indexOf(item)+1}}</td>
                        <td class="text-center f18">{{item.job_title}}</td>
                        <td class="text-center f18">{{item.job_date.substr(0,10)}}</td>
                        <td class="text-center f18">
                            <v-btn icon @click="set_job_to_edit(item)">
                                <v-icon color="primary">mdi-pen</v-icon>
                            </v-btn>
                            <v-btn icon @click="set_job_to_delete(item)">
                                <v-icon color="error">mdi-delete</v-icon>
                            </v-btn>

                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </v-card-text>
            <v-divider/>
            <v-card elevation="1" class="f14 text-center pa-4">
                <jw-pagination :pageSize="15" :maxPages="$vuetify.breakpoint.xs?1:10"  :items="jobs" :labels="customLabels" :styles="customStyles" @changePage="onChangePage"></jw-pagination>
            </v-card>
        </v-card>
        <AddJob/>
        <EditJob/>
        <DeleteJob/>
    </v-container>
</template>

<script>
    import ToolBar from "@/views/Dashboard/ToolBar";
    import AddJob from "@/views/Dashboard/Jobs/AddJob";
    import EditJob from "@/views/Dashboard/Jobs/EditJob";
    import DeleteJob from "@/views/Dashboard/Jobs/DeleteJob";
    import JwPagination from 'jw-vue-pagination';
    const customLabels = {
        first: 'الاول',
        last: 'الاخير',
        previous: 'السابق',
        next: 'التالي'
    };
    const customStyles = {
        ul: {
            direction:'ltr'
        },
        li: {
            display: 'inline-block',
            border:'1px solid grey',
            margin:'2px',
            borderRadius:'5px'
        },
        a: {
            color: 'black'
        }
    };
    export default {
        name: "Jobs",
        components:{
            AddJob,
            DeleteJob,
            EditJob,
            JwPagination,
            ToolBar
        },
        data(){
            return{
                jobs:this.$store.state.dashboard.jobs.jobs,
                customLabels,
                customStyles,
                pageOfItems: [],
            }
        },
        methods: {
            onChangePage(pageOfItems) {
                // update page of items
                this.pageOfItems = pageOfItems;
            },
            set_job_to_edit(job)
            {
                this.$store.state.dashboard.jobs.target_job = job;
                this.$store.state.dashboard.jobs.forms.edit_job = true;
            },
            set_job_to_delete(job)
            {
                this.$store.state.dashboard.jobs.target_job = job;
                this.$store.state.dashboard.jobs.forms.delete_job = true;
            },

        },
        computed:{
            get_jobs:function () {
                return this.$store.state.dashboard.jobs.jobs;
            }
        },
        watch:{
            get_jobs:function (new_jobs) {
                this.jobs = new_jobs;
            }
        },
        created(){
            this.$store.commit("GET_JOBS");
        }
    }
</script>

<style scoped>
.f18{
    font-size: 16px !important;
}
</style>