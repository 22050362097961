<template>
    <!-- App.vue -->

    <v-app>
<!---- AppBarMobile---->
        <v-app-bar
                color="#faad03"
                outlined
                max-height="80"
                dark
                v-if="$store.state.style && $vuetify.breakpoint.xs"
        >
            <v-menu
                    left
                    bottom
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            class="mr-4"
                    >
                        <v-icon>mdi-menu</v-icon>
                    </v-btn>
                </template>

                <v-list  width="200">
                    <v-list-item to="/">
                        <v-list-item-title>
                            <v-icon>mdi-home</v-icon>
                            <span class="mx-2">
                                <span v-if="!$store.state.en">الرئيسية</span>
                                <span v-if="$store.state.en">Home</span>
                            </span>
                        </v-list-item-title>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item to="/news">
                        <v-list-item-title>
                            <v-icon>mdi-earth</v-icon>
                            <span class="mx-2">
                                <span v-if="!$store.state.en">الاخبار</span>
                                <span v-if="$store.state.en">News</span>
                            </span>
                        </v-list-item-title>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item to="/projects">
                        <v-list-item-title>
                            <v-icon>mdi-finance</v-icon>
                            <span class="mx-2">
                                <span v-if="!$store.state.en">المشاريع</span>
                                <span v-if="$store.state.en">Projects</span>
                            </span>
                        </v-list-item-title>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item to="/jobs">
                        <v-list-item-title>
                            <v-icon>mdi-briefcase</v-icon>
                            <span class="mx-2">
                                <span v-if="!$store.state.en">الوظائف</span>
                                <span v-if="$store.state.en">Jobs</span>
                            </span>
                        </v-list-item-title>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item to="/partnership">
                        <v-list-item-title>
                            <v-icon>mdi-arrow-top-right-thick</v-icon>
                            <span class="mx-2">
                                <span v-if="!$store.state.en">الشراكات</span>
                                <span v-if="$store.state.en">Partnership</span>
                            </span>
                        </v-list-item-title>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item to="/gallery">
                        <v-list-item-title>
                            <v-icon>mdi-image</v-icon>
                            <span class="mx-2">
                                <span v-if="!$store.state.en">المعرض</span>
                                <span v-if="$store.state.en">Gallery</span>
                            </span>
                        </v-list-item-title>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item to="/about-us">
                        <v-list-item-title>
                            <v-icon>mdi-information</v-icon>
                            <span class="mx-2">
                               <span v-if="!$store.state.en">من نحن</span>
                                <span v-if="$store.state.en">About Us</span>
                            </span>
                        </v-list-item-title>
                    </v-list-item>


                </v-list>

            </v-menu>

            <div style="font-size: 15px" v-if="!$store.state.en">
                مؤسسة سنابل الغربية
             <span  style="font-size: 15px">للاغاثة والتنمية</span>
            </div>
            <div v-if="$store.state.en" class="pa-2 mt-2">
                SANABEL ALGHARBIA
                 <p  class='align-self-center text-left'>for relief and development</p>
            </div>
            <v-spacer/>
            <router-link to="/">
                <div class="d-flex mx-2" style="width: 45px;height: 45px;background-color: white;border-radius: 50%">
                    <v-img contain class="align-self-center" width="40" height="40" src="https://sanabelalgharbia.com/logo.png"/>
                </div>

            </router-link>




        </v-app-bar>
        <v-toolbar dark max-height="60">
            <v-spacer></v-spacer>
            <div  class="text-center mr-2">
                <v-menu offset-y :close-on-content-click="false" class="ma-0">
                    <template v-slot:activator="{ on, attrs }">

                        <v-btn v-bind="attrs"
                               v-on="on"
                               small
                               icon><v-icon color="white">mdi-email</v-icon></v-btn>
                    </template>
                    <v-list>
                        <v-list-item>
                            <v-list-item-title>
                                <a class="text-decoration-none" style="color: black" :href="'mailto:'+settings.email">
                                    {{settings.email}}
                                </a>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
            <div  class="text-center mr-2">
                <v-menu offset-y :close-on-content-click="false" class="ma-0 mr-2">
                    <template v-slot:activator="{ on, attrs }">

                        <v-btn v-bind="attrs"
                               v-on="on"
                               small
                               icon><v-icon color="white">mdi-phone</v-icon></v-btn>
                    </template>
                    <v-list>
                        <v-list-item>
                            <v-list-item-title>
                                <a class="text-decoration-none" style="color: black" :href="'tel:'+settings.phone1">
                                    {{settings.phone1}}
                                </a>
                            </v-list-item-title>
                        </v-list-item>
                        <v-divider/>
                        <v-list-item>
                            <v-list-item-title>
                                <a class="text-decoration-none" style="color: black" :href="'tel:'+settings.phone1">
                                    {{settings.phone2}}
                                </a>
                            </v-list-item-title>
                        </v-list-item>

                    </v-list>
                </v-menu>
            </div>
            <div  class="text-center mr-2">
                <v-menu offset-y :close-on-content-click="false" class="ma-0 mr-2" >
                    <template v-slot:activator="{ on, attrs }">

                        <v-btn v-bind="attrs"
                               v-on="on"
                               small
                               icon><v-icon color="white">mdi-facebook</v-icon></v-btn>
                    </template>
                    <v-list>
                        <v-list-item>
                            <v-list-item-title>
                                <a class="text-decoration-none" style="color: black" :href="'https://'+settings.facebook">
                                    {{settings.facebook}}
                                </a>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
            <div  class="text-center mr-2">
                <v-menu offset-y :close-on-content-click="false" class="ma-0 mr-2">
                    <template v-slot:activator="{ on, attrs }">

                        <v-btn v-bind="attrs"
                               v-on="on"
                               small
                               icon><v-icon color="white">mdi-map-marker</v-icon></v-btn>
                    </template>
                    <v-list>
                        <v-list-item>
                            <v-list-item-title>
                                <a class="text-decoration-none" style="color: black">
                                    {{settings.address}}
                                </a>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
            <div  class="text-center mr-2">
                <v-menu offset-y :close-on-content-click="false" class="ma-0 mr-2">
                    <template v-slot:activator="{ on, attrs }">

                        <v-btn v-bind="attrs"
                               small
                               v-on="on" text> AR</v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-for="(la , i ) in languages" :key="'lang__i__'+i" @click="change_language(la.value)">
                            <v-list-item-title>
                                <a class="text-decoration-none" style="color: black">
                                    {{la.label}}
                                </a>
                            </v-list-item-title>
                        </v-list-item>


                    </v-list>
                </v-menu>
            </div>


        </v-toolbar>
        <!---- ُEndAppBarMobile---->

        <v-app-bar  class="pa-0" elevation="8" app :height="$vuetify.breakpoint.xs?100:150" color="white"
                   v-if="$store.state.style && !$vuetify.breakpoint.xs">
            <div class="pa-0 ma-0" style="width: 100%;height: 100%;position: relative">

                <v-row>

                    <v-col cols="2">
                        <div>
                            <router-link to="/">
                                <v-img contain class="float-right align-self-center" height="150" src="https://sanabelalgharbia.com/logo.png"/>
                            </router-link>

                        </div>
                    </v-col>
                    <v-col cols="2">
                        <div class="my-d-flex">
                            <router-link to="/" class="align-self-center float-right main-title text-decoration-none">
                            <h3 v-if="!$store.state.en" style="color: #faad03;">مؤسسة سنابل الغربية</h3>
                                <p v-if="!$store.state.en" class='align-self-center'>للاغاثة والتنمية</p>
                            <h3 v-if="$store.state.en" style="color: #faad03">SANABEL ALGHARBIA</h3>
                                <p v-if="$store.state.en" class='align-self-center text-left'>for relief and development</p>
                            </router-link>
                        </div>
                    </v-col>
                    <v-col cols="6">
                        <div class="my-d-flex">
                            <div class="align-self-center float-left">

                                <ul v-if="!$store.state.en" id="myul" style="list-style: none;">
                                    <li>
                                        <router-link to="/" class="text-decoration-none" style="color: rgba(45,45,45,0.89)">
                                            <div class="div-in-li">
                                                <div class="f20">الرئيسية</div>
                                            </div>
                                            <div class="under-line"></div>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link to="/news" class="text-decoration-none" style="color: rgba(45,45,45,0.89)">
                                            <div class="div-in-li">
                                                <div class="f20">الاخبار</div>
                                            </div>
                                            <div class="under-line"></div>
                                        </router-link>

                                    </li>
                                    <li>
                                        <div class="text-decoration-none" style="color: rgba(45,45,45,0.89);">
                                            <div class="div-in-li" style="width: 150px;">
                                                <div  class="text-center"  style="margin-top: -5px">
                                                    <v-menu  :close-on-content-click="true">
                                                        <template v-slot:activator="{ on, attrs }">

                                                            <div v-bind="attrs"
                                                                   v-on="on"
                                                                 class="f20 text-center"
                                                                   >النشاطات</div>
                                                        </template>
                                                        <v-list class="mt-10">
                                                            <v-list-item to="/projects">
                                                                <v-list-item-title>
                                                                    <a class="text-decoration-none" style="color: black" >
                                                                        <v-icon small>mdi-finance</v-icon>
                                                                        <span class="mr-2">المشاريع</span>
                                                                    </a>
                                                                </v-list-item-title>
                                                            </v-list-item>
                                                            <v-divider/>
                                                            <v-list-item to="/jobs">
                                                                <v-list-item-title>
                                                                    <a class="text-decoration-none" style="color: black" >
                                                                        <v-icon small>mdi-briefcase</v-icon>
                                                                        <span class="mr-2">االوظائف</span>
                                                                    </a>
                                                                </v-list-item-title>
                                                            </v-list-item>
                                                            <v-divider/>
                                                            <v-list-item to="/partnership">
                                                                <v-list-item-title>
                                                                    <a class="text-decoration-none" style="color: black" >
                                                                        <v-icon small>mdi-arrow-top-right-thick</v-icon>
                                                                        <span class="mr-2">الشراكات</span>
                                                                    </a>
                                                                </v-list-item-title>
                                                            </v-list-item>


                                                        </v-list>
                                                    </v-menu>
                                                </div>
                                            </div>
                                            <div class="under-line"></div>
                                        </div>

                                    </li>

                                    <li>
                                        <router-link to="/gallery" class="text-decoration-none" style="color: rgba(45,45,45,0.89)">
                                            <div class="div-in-li">
                                                <div class="f20">المعرض</div>
                                            </div>
                                            <div class="under-line"></div>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link to="/about-us" class="text-decoration-none" style="color: rgba(45,45,45,0.89)">
                                            <div class="div-in-li">
                                                <div class="f20">من نحن</div>
                                            </div>
                                            <div class="under-line"></div>
                                        </router-link>
                                    </li>



                                </ul>
                                <ul v-if="$store.state.en" id="myul" style="list-style: none;">
                                    <li>
                                        <router-link to="/about-us" class="text-decoration-none" style="color: rgba(45,45,45,0.89)">
                                            <div class="div-in-li">
                                                <div class="f20">About Us</div>
                                            </div>
                                            <div class="under-line"></div>
                                        </router-link>
                                    </li>

                                    <li>
                                        <router-link to="/gallery" class="text-decoration-none" style="color: rgba(45,45,45,0.89)">
                                            <div class="div-in-li">
                                                <div class="f20">Gallery</div>
                                            </div>
                                            <div class="under-line"></div>
                                        </router-link>
                                    </li>
                                    <li class="pa-0">
                                        <div class="text-decoration-none pa-0"  style="color: rgba(45,45,45,0.89);">
                                            <div class="div-in-li pa-0" style="width: 150px;">
                                                <div  class="text-center pa-0" style="margin-top: -5px">
                                                    <v-menu  :close-on-content-click="true" class="pa-0">
                                                        <template v-slot:activator="{ on, attrs }">

                                                            <div v-bind="attrs"
                                                                 v-on="on"
                                                                 class="f20 text-center pa-0"
                                                            >
                                                                <span class="mb-8">Activities</span>
                                                            </div>
                                                        </template>
                                                        <v-list class="mt-10">
                                                            <v-list-item to="/projects">
                                                                <v-list-item-title>
                                                                    <a class="text-decoration-none" style="color: black" >
                                                                        <v-icon small>mdi-finance</v-icon>
                                                                        <span class="ml-2">Projects</span>
                                                                    </a>
                                                                </v-list-item-title>
                                                            </v-list-item>
                                                            <v-divider/>
                                                            <v-list-item to="/jobs">
                                                                <v-list-item-title>
                                                                    <a class="text-decoration-none" style="color: black" >
                                                                        <v-icon small>mdi-briefcase</v-icon>
                                                                        <span class="ml-2">Jobs</span>
                                                                    </a>
                                                                </v-list-item-title>
                                                            </v-list-item>
                                                            <v-divider/>
                                                            <v-list-item to="/partnership">
                                                                <v-list-item-title>
                                                                    <a class="text-decoration-none" style="color: black" >
                                                                        <v-icon small>mdi-arrow-top-right-thick</v-icon>
                                                                        <span class="ml-2">Partnership</span>
                                                                    </a>
                                                                </v-list-item-title>
                                                            </v-list-item>


                                                        </v-list>
                                                    </v-menu>
                                                </div>
                                            </div>
                                            <div class="under-line"></div>
                                        </div>

                                    </li>
                                    <li>
                                        <router-link to="/news" class="text-decoration-none" style="color: rgba(45,45,45,0.89)">
                                            <div class="div-in-li">
                                                <div class="f20">News</div>
                                            </div>
                                            <div class="under-line"></div>
                                        </router-link>

                                    </li>
                                    <li>
                                        <router-link to="/" class="text-decoration-none" style="color: rgba(45,45,45,0.89)">
                                            <div class="div-in-li">
                                                <div class="f20">Home</div>
                                            </div>
                                            <div class="under-line"></div>
                                        </router-link>
                                    </li>


                                </ul>

                            </div>
                        </div>
                    </v-col>
                    <v-col cols="2">
                        <div style="background-color: #faad03;max-width: 250px;border-radius: 20px;height: 40px" class="pa-2  mt-2 d-flex">
                            <div v-if="settings" class="d-flex align-self-center">
                                <div  class="text-center">
                                    <v-menu offset-y :close-on-content-click="false">
                                        <template v-slot:activator="{ on, attrs }">

                                                <v-btn v-bind="attrs"
                                                       v-on="on"
                                                       icon><v-icon color="white">mdi-email</v-icon></v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item>
                                                <v-list-item-title>
                                                    <a class="text-decoration-none" style="color: black" :href="'mailto:'+settings.email">
                                                        {{settings.email}}
                                                    </a>
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </div>
                                <div  class="text-center">
                                    <v-menu offset-y :close-on-content-click="false">
                                        <template v-slot:activator="{ on, attrs }">

                                            <v-btn v-bind="attrs"
                                                   v-on="on"
                                                   icon><v-icon color="white">mdi-phone</v-icon></v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item>
                                                <v-list-item-title>
                                                    <a class="text-decoration-none" style="color: black" :href="'tel:'+settings.phone1">
                                                        {{settings.phone1}}
                                                    </a>
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-divider/>
                                            <v-list-item>
                                                <v-list-item-title>
                                                    <a class="text-decoration-none" style="color: black" :href="'tel:'+settings.phone1">
                                                        {{settings.phone2}}
                                                    </a>
                                                </v-list-item-title>
                                            </v-list-item>

                                        </v-list>
                                    </v-menu>
                                </div>
                                <div  class="text-center">
                                    <v-menu offset-y :close-on-content-click="false">
                                        <template v-slot:activator="{ on, attrs }">

                                            <v-btn v-bind="attrs"
                                                   v-on="on"
                                                   icon><v-icon color="white">mdi-facebook</v-icon></v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item>
                                                <v-list-item-title>
                                                    <a class="text-decoration-none" style="color: black" :href="'https://'+settings.facebook">
                                                        {{settings.facebook}}
                                                    </a>
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </div>
                                <div  class="text-center">
                                    <v-menu offset-y :close-on-content-click="false">
                                        <template v-slot:activator="{ on, attrs }">

                                            <v-btn v-bind="attrs"
                                                   v-on="on"
                                                   icon><v-icon color="white">mdi-map-marker</v-icon></v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item>
                                                <v-list-item-title>
                                                    <a class="text-decoration-none" style="color: black">
                                                        {{settings.address}}
                                                    </a>
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </div>
                                <div  class="text-center">
                                    <v-menu offset-y  class="ma-0 mr-2">
                                        <template v-slot:activator="{ on, attrs }">

                                            <div v-bind="attrs" class="pa-3" style="color: white"
                                                   v-on="on" >
                                                <span v-if="$store.state.en">AR</span>
                                                <span v-if="!$store.state.en">EN</span>
                                                <span></span>
                                            </div>
                                        </template>
                                        <v-list>
                                            <v-list-item v-for="(la , i ) in languages" :key="'lang__i__'+i" @click="change_language(la.value)">
                                                <v-list-item-title>
                                                    <a class="text-decoration-none" style="color: black">
                                                        {{la.label}}
                                                    </a>
                                                </v-list-item-title>
                                            </v-list-item>


                                        </v-list>
                                    </v-menu>
                                </div>
                            </div>
                        </div>

                    </v-col>


                </v-row>

                <div class="line-bar pa-0 ma-0"
                     style="background-color: red;width: 100%;height: 7px;position: absolute;bottom: 0px"></div>

            </div>
        </v-app-bar>
        <!-- Sizes your content based upon application components -->
        <v-main>

            <!-- Provides the application the proper gutter -->
            <v-container fluid class="pa-0">

                <!-- If using vue-router -->
                <router-view></router-view>
            </v-container>
        </v-main>

        <Footer v-if="$store.state.style"/>
    </v-app>
</template>

<script>
    // import AppBar from "@/components/AppBar";
    import Footer from "@/components/Footer";

    export default {
        name: 'App',
        components: {
            //      AppBar,
            Footer
        },
        data(){
            return{
                settings:{},
                languages:[
                    {label:'AR',value:false},
                    {label:'EN',value:true},
                ],

            }
        },
        methods:{
            change_language(val)
            {
                this.$store.state.en = val
                if(val == true)
                {
                    this.$vuetify.rtl = false;
                }else{
                    this.$vuetify.rtl = true;
                }
            }
        },
        computed:{
            get_settings:function () {
                return this.$store.state.clients.settings;
            }
        },
        watch:{
            get_settings:function (new_settings) {
                this.settings = new_settings[0];

            }
        },

        created(){
            var url = this.$route.path.split('/dashboard')[1];
            if (url != null || url != undefined) {
                this.$store.state.style = false;
            } else {
                this.$store.state.style = true;
            }

            this.$axios.get('api/get-settings').then(res=>{
                this.settings = res.data;
                this.$store.state.clients.settings.settings = res.data;
            })

        }
    };
</script>
<style>

    @import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Cairo:wght@200;400;500;700&family=Josefin+Sans:wght@700&family=Righteous&display=swap');

    * {
        font-family: 'Cairo', sans-serif;
    }

    .main-title {
        transition: .3s linear;
        color: #faad03;

    }

    .line-bar {
        background: rgb(227, 54, 153);
        background: linear-gradient(76deg, rgba(253, 209, 13, 1) 19%, rgba(148, 190, 7, 1) 48%, rgba(241, 211, 8, 1) 76%);
    }

    .v-toolbar__content, .v-toolbar__extension {
        padding: 0px !important;
    }

    .my-d-flex {
        height: 100%;
        width: 100%;
        display: flex;
    }

    .under-line {
        margin-top: 5px;
        width: 100%;
        transition: .3s linear;
        height: 0px;
        background-color: #faad03 !important;
    }

    .div-in-li:hover > .under-line {
        background-color: #faad03 !important;
        width: 100%;
        height: 10px;
    }
#myul li{
    float: right;
    transition: .3s linear;
}
    .div-in-li {
        cursor: pointer;
        transition: .3s linear;
        float: right;
        margin-right: 30px;
        color: rgba(1, 1, 1, 0.75);
        border-left: 1px solid rgba(0, 0, 0, 0.67);
        text-align: center;
        width: 100px;
    }

    .div-in-li:hover {
        transform: translateY(-15px);
        color: #faad03;

    }

    .f20 {
        font-size: 20px;
        font-weight: bold;
    }

</style>