<template>
    <v-container>
        <v-row justify="center">
            <v-col>
                <v-card class="mt-16" elevation="8">
                    <v-card-title>
                        <div v-if="!$store.state.en">
                            {{the_new.post_title}}
                        </div>
                        <div v-if="$store.state.en">
                            {{the_new.post_title_en}}
                        </div>

                    </v-card-title>
                    <v-divider/>
                    <v-card-text>
                        <v-img contain :src="axios.defaults.baseURL+the_new.post_splash" max-height="600"/>
                        <v-row justify="center" class="mt-16">
                            <v-col cols="12" md="6">
                                <v-row justify="center">
                                    <v-col cols="12" md="6" lg="6" v-for="(item,i) in images" :key="i" class="d-flex">
                                        <v-img contain :src="axios.defaults.baseURL+item.image_name" max-width="300" class="d-flex align-self-center ma-auto"/>
                                    </v-col>
                                </v-row>
                            </v-col>

                        </v-row>

                    </v-card-text>
                    <v-divider/>
                    <v-card-text>
                        <v-row justify="center">
                            <v-col cols="12" md="6">
                                <div v-if="!$store.state.en" class="text-justify">
                                    {{the_new.post_text}}
                                </div>
                                <div  v-if="$store.state.en" class="text-justify">
                                    {{the_new.post_text_en}}
                                </div>

                            </v-col>
                        </v-row>

                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "ReadNew",
        data(){
            return{
                the_new:{
                    post_title:'',
                    post_text:'',
                    post_date:''
                },
                images:[]
            }
        },
        methods:{
            get_the_new()
            {
                var id = this.$route.path.split('read/')[1];
                this.$axios.get('api/news/read/'+id).then(res=>{
                    this.the_new = res.data.new[0];
                    this.images = res.data.images;
                })
            }
        },


        created(){
            this.get_the_new()
        }
    }
</script>

<style scoped>

</style>