<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" md="3">
                <v-card class="mt-16">
                    <v-card-title class="error">
                        <v-icon dark>mdi-account</v-icon>
                        <span style="color: white">تسجيل دخول</span>
                    </v-card-title>
                    <v-divider/>
                    <v-card-text>
                        <v-text-field v-model="user.email" label="اسم المستخدم"/>
                        <v-text-field type="password" v-model="user.password" label="كلمة المرور"/>
                    </v-card-text>
                    <v-divider/>
                    <v-card-actions>
                        <v-btn :loading="loading" @click="login" color="primary">دخول</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "Login",
        data(){
            return{
                user:{
                    email:'',
                    password:''
                },
                loading:false,
            }
        },
        methods:{
            login()
            {
                this.loading = true;
                this.$axios.post('api/login',this.user).then(res=>{
                    localStorage.setItem('token',res.data.token);
                    localStorage.setItem('user_id',res.data.user_id);
                    localStorage.setItem('user_level',res.data.user_level);
                    this.$store.state.dashboard.user = res.data;
                    this.$router.push("/dashboard/posts");
                    this.$axios.defaults.headers.common['Authorization'] = 'Bearer '+res.data.token;
                    this.$store.state.style = false;
                    this.$router.push('posts');
                   // window.location.href = '/dashboard/posts';
                }).catch(err=>{
                    this.$fire({
                        title: "عفوا",
                        text: 'اسم المستخدم او كلمة المرور غير صحيحة',
                        type: "error",
                        timer: 5000
                    })
                }).finally(fin=>{
                    this.loading = false;
                })
            }
        }
    }
</script>

<style scoped>

</style>