<template>
    <v-container>
        <v-card>
                <ToolBar/>
            <v-divider/>
            <v-card-title>
                <v-btn @click="$store.state.dashboard.posts.forms.add_post=true" small fab color="primary"><v-icon dark>mdi-plus</v-icon></v-btn>
            </v-card-title>
            <v-divider/>
            <v-card-text>
                <v-simple-table v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-center"></th>
                        <th class="text-center">
                            <v-text-field @keyup="search_post" v-model="search.post_title" outlined prepend-inner-icon="mdi-magnify" dense/>
                        </th>
                        <th class="text-center">
                            <v-text-field @keyup="search_post" v-model="search.post_date" outlined prepend-inner-icon="mdi-magnify" dense/>
                        </th>
                        <th class="text-center">
                            <v-text-field  @keyup="search_post" v-model="search.name" outlined prepend-inner-icon="mdi-magnify" dense/>
                        </th>

                        <th class="text-center"></th>
                    </tr>
                    <tr>
                        <th class="text-center">ت</th>
                        <th class="text-center">العنوان</th>
                        <th class="text-center">التاريخ</th>
                        <th class="text-center">الناشر</th>
                        <th class="text-center">الخيارات</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in pageOfItems" :key="item.post_id">
                        <td class="text-center">{{posts.indexOf(item)+1}}</td>
                        <td class="text-center">{{item.post_title}}</td>
                        <td class="text-center">{{item.post_date}}</td>
                        <td class="text-center">{{item.name}}</td>
                        <td class="text-center">
                            <v-btn icon @click="set_post_to_edit(item)">
                                <v-icon color="primary">mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn icon @click="set_post_to_delete(item)">
                                <v-icon color="error">mdi-delete</v-icon>
                            </v-btn>

                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </v-card-text>
            <v-divider/>
            <v-card elevation="1" class="f14 text-center pa-4">
                <jw-pagination :pageSize="15" :maxPages="$vuetify.breakpoint.xs?1:10"  :items="posts" :labels="customLabels" :styles="customStyles" @changePage="onChangePage"></jw-pagination>
            </v-card>
        </v-card>
        <AddPost/>
        <EditPost/>
        <DeletePost/>
    </v-container>
</template>

<script>
    import ToolBar from "@/views/Dashboard/ToolBar";
    import JwPagination from 'jw-vue-pagination';
    import AddPost from "@/views/Dashboard/Posts/AddPost";
    import EditPost from "@/views/Dashboard/Posts/EditPost";
    import DeletePost from "@/views/Dashboard/Posts/DeletePost";
    const customLabels = {
        first: 'الاول',
        last: 'الاخير',
        previous: 'السابق',
        next: 'التالي'
    };
    const customStyles = {
        ul: {
            direction:'ltr'
        },
        li: {
            display: 'inline-block',
            border:'1px solid grey',
            margin:'2px',
            borderRadius:'5px'
        },
        a: {
            color: 'black'
        }
    };
    export default {
        name: "Posts",
        components:{
            JwPagination,
            AddPost,
            EditPost,
            DeletePost,
            ToolBar
        },
        data(){
            return{
                posts:this.$store.state.dashboard.posts.posts,
                customLabels,
                customStyles,
                pageOfItems: [],
                search:{
                    post_title:'',
                    post_date:'',
                    name:''
                }
            }
        },
        methods:{
            onChangePage(pageOfItems) {
                // update page of items
                this.pageOfItems = pageOfItems;
            },
            set_post_to_edit(post)
            {
                this.$store.state.dashboard.posts.target_post = post;
                this.$store.state.dashboard.posts.forms.edit_post=true;

            },
            set_post_to_delete(post)
            {
                this.$store.state.dashboard.posts.target_post = post;
                this.$store.state.dashboard.posts.forms.delete=true;

            },

            search_post()
            {
                var filterd = this.$store.state.dashboard.posts.posts;

                filterd = filterd.filter(item=>item.post_title.match(this.search.post_title));
                filterd = filterd.filter(item=>item.post_date.match(this.search.post_date));
                filterd = filterd.filter(item=>item.name.match(this.search.name));

                this.posts = filterd;
            }
        },
        computed:{
            get_posts:function () {
                return this.$store.state.dashboard.posts.posts;
            }
        },
        watch:{
            get_posts:function (new_posts) {
                this.posts = new_posts;
            }
        },

        created()
        {
            this.$store.commit("GET_POSTS");
        }
    }
</script>

<style scoped>

</style>