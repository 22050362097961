<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12">
                <v-card class="mt-16">
                    <v-card-title>
                        <h2 v-if="!$store.state.en">من نحن</h2>
                        <h2 v-if="$store.state.en">About Us</h2>
                    </v-card-title>
                    <v-divider/>
                    <v-card-text>
                        <div  v-if="!$store.state.en" class="pa-4" v-html="about_us"></div>
                        <div  v-if="$store.state.en" class="pa-4" v-html="about_us_en"></div>


                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

    export default {
        name: "AboutUs",
        components:{
        },
        data(){
            return{
                about_us:'',
                about_us_en:'',
            }
        },
        created(){
            this.$axios.get('api/about-us').then(res=>{
                this.about_us = res.data.about_us;
                this.about_us_en = res.data.about_us_en;
            })
        }
    }
</script>

<style scoped>

</style>