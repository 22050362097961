<template>
    <v-container>
        <v-dialog v-model="$store.state.dashboard.projects.forms.delete_project" max-width="500">
            <v-card>
                <v-card-title class="error">
                    <v-icon dark>mdi-delete</v-icon>
                    <span style="color: white" class="mr-2">حذف مشروع</span>
                </v-card-title>
                <v-divider/>
                <v-card-text>
                    <div class="text-center pa-8" style="width: 100%;">
                        <h3 >هل انت متأكد من حذف المشروع</h3>
                        <h3 >{{project.project_title}}</h3>
                    </div>
                </v-card-text>
                <v-divider/>
                <v-card-actions>
                    <v-btn @click="delete_project" :loading="loading" color="primary">حذف</v-btn>
                    <v-btn outlined @click="$store.state.dashboard.projects.forms.delete_project=false">اللغاء</v-btn>

                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    export default {
        name: "DeleteJob",
        data(){
            return{
                project:{
                    project_id:0,
                    project_title:'',
                    project_desc:''
                },
                loading:false
            }
        },
        methods:{
            async delete_project()
            {
                this.loading = true;
                await  this.$axios.post('api/delete-project',this.project).then(res=>{
                    this.$store.commit("GET_PROJECTS");
                    this.$store.state.dashboard.projects.forms.delete_project=false;
                    this.$fire({
                        title: "نجح",
                        text: res.data.msg,
                        type: "success",
                        timer: 3000
                    });
                }).catch(err=>{
                    console.log(err.response.data)
                }).finally(fin=>{
                    this.loading = false
                })
            }
        },
        computed:{
            get_projects:function () {
                return this.$store.state.dashboard.projects.target_project;
            }
        },
        watch:{
            get_projects:function (new_proj) {
                this.project = new_proj
            }
        },

    }
</script>

<style scoped>

</style>