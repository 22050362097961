<template>
  <div class="home">
    <v-carousel cycle interval="3000" hide-delimiters :height="$vuetify.breakpoint.xs?300:$vuetify.breakpoint.lg?600:$vuetify.breakpoint.xl?800:400" >
      <v-carousel-item v-for="(slide,i) in slides" :key="'slide__'+i" >
        <router-link :to="'/news/read/'+slide.post_id">
          <v-img contain :src="$axios.defaults.baseURL+slide.post_splash"></v-img>
        </router-link>

      </v-carousel-item>

    </v-carousel>

    <v-divider/>
    <v-card height="100" >
      <div style="width: 100%;height: 100%" class="text-center d-flex">
        <div class="d-flex align-self-center" style="width: 100%;">
          <div v-if="!$store.state.en" style="width: 100%;height: 100%;" class="text-right pa-4">
            <h2  class="d-inline mr-4">اخر الاخبار</h2>
          </div>
          <div v-if="$store.state.en" style="width: 100%;height: 100%;" class="text-left pa-4">
            <h2  class="d-inline mr-4">Last News </h2>
          </div>


        </div>
      </div>

    </v-card>
    <v-divider/>
    <v-row>
      <v-col cols="12" md="8" lg="10">
        <v-row>
          <v-col cols="12" md="4" v-for="(item,i) in news"
                  :key="'new_1__'+i">
            <v-card
                    height="600"
                    :loading="loading"
                    class="my-12 mx-4"
            >
              <router-link :to="'news/read/'+item.post_id">
                <v-img

                        width="100%" max-height="300"
                        :src="$axios.defaults.baseURL+item.post_splash"
                ></v-img>
              </router-link>



              <v-card-title>
                <div v-if="!$store.state.en">
                  {{item.post_title}}
                </div>
                <div v-if="$store.state.en">
                  {{item.post_title_en}}
                </div>

              </v-card-title>

              <v-card-text>
                <v-row

                        class="mx-0"
                >


                  <div class="grey--text ms-4">
                    {{item.post_date}}
                  </div>
                </v-row>

                <div class="my-4 text-subtitle-1">
                 <span v-if="!$store.state.en">منشور</span>
                 <span v-if="$store.state.en">News</span>
                </div>

                <div v-if="!$store.state.en" class="text-justify">
                  {{item.post_text.substr(0,100)}} ...
                </div>
                <div v-if="$store.state.en" class="text-justify">
                  {{item.post_text_en.substr(0,100)}} ...
                </div>

              </v-card-text>

              <v-divider class="mx-4"></v-divider>

              <v-card-text>
                <v-chip-group
                        v-model="selection"
                        active-class="deep-purple accent-4 white--text"
                        column
                >

                </v-chip-group>
              </v-card-text>

              <v-card-actions>
                <v-btn
                        color="deep-purple lighten-2"
                        text
                        :to="'news/read/'+item.post_id"
                >
                 <span v-if="!$store.state.en"> قراءة المزيد ...</span>
                 <span v-if="$store.state.en"> Read More ...</span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="2" class="pa-4">
        <v-row>
          <v-col cols="12">
            <v-card
                    class="ma-auto mt-11"
            >
              <v-date-picker width="100%" v-model="picker" :locale="$store.state.en?'en-US':'ar-IQ'" color="#faad03"></v-date-picker>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card
                    class="ma-auto mt-11"
                    max-height="1000"
            >
              <div style="width: 100%" class="text-center pa-4">
                <h3 v-if="!$store.state.en">حسب التاريخ</h3>
                <h3 v-if="$store.state.en">History</h3>
              </div>

              <v-card-text>
                <v-list>
                  <v-list-item v-for="(li , i) in $store.state.clients.news.news" :key="'list_item_new_'+i" :to="'/news/read/'+li.post_id">
                    <v-list-item-avatar>
                      <v-img  :src="$axios.defaults.baseURL+li.post_splash"/>
                    </v-list-item-avatar>
                    <v-list-item-title v-if="!$store.state.en">{{li.post_title}}</v-list-item-title>
                    <v-list-item-title v-if="$store.state.en">{{li.post_title_en}}</v-list-item-title>
                    <v-list-item-subtitle>{{li.post_date.substr(0,10)}}</v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>

        </v-row>

      </v-col>
    </v-row>
    <v-parallax
            src="logo-par.png"
            height="400"

    >
      <v-row
              align="center"
              justify="center"
      >
        <v-col
                class="text-center"
                cols="12"
        >






        </v-col>
      </v-row>
    </v-parallax>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data: () => ({
    isActive: false,
    slides:[],
    news:[],
    loading: false,
    selection: 1,
    labels: ['SU', 'MO', 'TU', 'WED', 'TH', 'FR', 'SA'],
    time: 0,
    forecast: [
      { day: 'Tuesday', icon: 'mdi-white-balance-sunny', temp: '24\xB0/12\xB0' },
      { day: 'Wednesday', icon: 'mdi-white-balance-sunny', temp: '22\xB0/14\xB0' },
      { day: 'Thursday', icon: 'mdi-cloud', temp: '25\xB0/15\xB0' },
    ],
    picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    slides2:[1,2,3,4,5,21,22,23]
  }),


  methods: {


  },
  computed:{
    get_slides:function () {
      return this.$store.state.clients.slides.slides;
    },
    get_news:function () {
      return this.$store.state.clients.news.news;
    },

  },
  watch:{
    get_slides:function (new_slides) {
      this.slides = new_slides;
    },
    get_news:function (new_news) {
      this.news = new_news;
    },

  },
  created(){
    this.$store.commit("RENDER");
  }
}
</script>

<style>
  .ani{
    animation-name: example;
    animation-duration: 5s;
    margin-right: -400px;
  }

  @keyframes example {
    from {transform: scale(1)}
    to {transform: scale(2)}
  }
</style>
