<template>
    <v-container>
        <v-card >
                <ToolBar/>
            <v-divider/>
            <v-card-title>
                <v-icon>mdi-information</v-icon>
                <span >الشراكات</span>
            </v-card-title>
            <v-divider/>
            <v-card-text>
                <VueEditor v-model="partnership"/>
                <VueEditor class="mt-4" v-model="partnership_en"/>

            </v-card-text>
            <v-divider/>
            <v-card-actions>
                <v-btn :loading="loading" @click="save" max-width="400" block color="primary">
                    حقظ
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
    import ToolBar from "@/views/Dashboard/ToolBar";
    import { VueEditor } from "vue2-editor";
    export default {
        name: "Partnership",
        components:{
            ToolBar,
            VueEditor
        },
        data(){
            return{
                partnership:'',
                partnership_en:'',
                loading:false
            }
        },
        methods:{
            async save()
            {
                this.loading = true;
                await this.$axios.post('api/save-partnership',{partnership:this.partnership,partnership_en:this.partnership_en}).then(res=>{
                    this.$fire({
                        title: "نجح",
                        text: res.data.msg,
                        type: "success",
                        timer: 3000
                    });
                }).catch(err=>{
                    var data = JSON.stringify(err.response.data);

                    this.$fire({
                        title: "عفوا",
                        text: data,
                        type: "warning",
                        timer: 3000
                    });

                }).finally(fin=>{
                    this.loading = false
                })
            },
            async get_partnership()
            {
                await this.$axios.get('api/get-partnership').then(res=>{
                    this.partnership = res.data.partnership;
                    this.partnership_en = res.data.partnership_en;
                }).catch(err=>{
                    var data = JSON.stringify(err.response.data);

                    this.$fire({
                        title: "عفوا",
                        text: data,
                        type: "warning",
                        timer: 3000
                    });

                })
            }
        },
        created(){
            this.get_partnership()
        }
    }
</script>

<style scoped>

</style>