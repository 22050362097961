<template>
    <v-dialog v-model="$store.state.dashboard.projects.forms.add_project" max-width="500">
        <v-form lazy-validation v-model="valid" ref="form">
            <v-card>
                <v-card-title class="primary">
                    <div style="color: white">اضافة مشروع</div>
                    <v-spacer/>
                    <v-btn icon dark @click="$store.state.dashboard.projects.forms.add_project=false"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-divider/>
                <v-card-text class="mt-4">
                    <v-row justify="center">
                        <v-col cols="12">
                            <v-text-field v-model="project.project_title" :rules="req" outlined label="عنوان المشروع"/>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="project.project_title_en" :rules="req" outlined label="عنوان المشروع بالانجليزية"/>
                        </v-col>

                        <v-col cols="12">
                            <v-textarea v-model="project.project_desc" :rules="req" outlined label="تفاصيل المشروع"/>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea v-model="project.project_desc_en" :rules="req" outlined label="تفاصيل المشروع بالانجليزية"/>
                        </v-col>
                        <v-col cols="12">
                            <v-sheet outlined class="d-flex" >

                                <v-img contain @click="$refs.file.click()" :src="project.project_image" min-width="300" min-height="250"  class="d-flex align-self-center" />
                                <input @change="onFileChange" ref="file" type="file" class="d-none" accept="image/*">
                            </v-sheet>
                            <v-text-field :rules="req" readonly v-model="project.project_image"/>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider/>
                <v-card-actions>
                    <v-btn outlined @click="$store.state.dashboard.projects.forms.add_project=false">اللغاء</v-btn>
                    <v-btn color="primary" class="mr-4" :loading="loading" @click="save_project">حفظ</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
    export default {
        name: "AddProject",
        data(){
            return{
                project:{
                    project_title:'',
                    project_title_en:'',
                    project_desc:'',
                    project_desc_en:'',
                    project_image:'',
                    project_file:''
                },
                req:[v=>!!v || 'هذا الحقل مطلوب'],
                valid:true,
                loading:false
            }
        },
        methods:{
            save_project()
            {
                if(this.$refs.form.validate())
                {
                    this.loading = true;
                    var fd = new FormData();
                    fd.append('project_title',this.project.project_title);
                    fd.append('project_title_en',this.project.project_title_en);
                    fd.append('project_desc',this.project.project_desc);
                    fd.append('project_desc_en',this.project.project_desc_en);
                     fd.append('project_file',this.project.project_file);

                    this.$axios.post('api/add-project',fd).then(res=>{
                        this.$store.commit('GET_PROJECTS');
                        this.$store.state.dashboard.projects.forms.add_project = false;
                        this.$fire({
                            title: "نجح",
                            text: res.data.msg,
                            type: "success",
                            timer: 3000
                        });
                    }).catch(err=>{
                        console.log(err)
                    }).finally(fin=>{
                        this.loading = false;
                    })
                }
            },
            onFileChange(e)
            {
                var file = e.target.files[0];
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.project.project_image = e.target.result;
                }
                reader.readAsDataURL(file);
                this.project.project_file = file;
            },
        }
    }
</script>

<style scoped>

</style>