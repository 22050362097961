<template>
    <v-container>
        <v-toolbar>
            <v-toolbar-items>
                <v-btn textto="dashboard">المنشورات</v-btn>
                <v-btn textto="dashboard">حولنا</v-btn>
                <v-btn textto="dashboard">اتصل بنا</v-btn>
            </v-toolbar-items>
        </v-toolbar>
    </v-container>
</template>

<script>
    export default {
        name: "MainDashboard"
    }
</script>

<style scoped>

</style>