<template>
    <v-container>
        <v-dialog v-model="$store.state.dashboard.posts.forms.delete" max-width="400">
            <v-card>
                <v-card-title class="error">
                    <v-icon dark>mdi-delete</v-icon>
                    <span style="color: white" class="mr-2">حذف منشور</span>
                </v-card-title>
                <v-divider/>
                <v-card-texts>
                    <div class="text-center pa-8" style="width: 100%;">
                        <h3 >هل انت متأكد من حذف المنشور</h3>
                        <h3 >{{post.post_title}}</h3>
                    </div>
                </v-card-texts>
                <v-divider/>
                <v-card-actions>
                    <v-btn @click="delete_post" :loading="loading" color="primary">حذف</v-btn>
                    <v-btn outlined @click="$store.state.dashboard.posts.forms.delete=false">اللغاء</v-btn>

                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    export default {
        name: "DeletePost",
        data(){
            return{
                loading:false,
                post:{
                    post_title:'',
                    post_id:''
                }
            }
        },
        methods:{
            async delete_post()
            {
                this.loading = true;
                await  this.$axios.post('api/delete-post',this.post).then(res=>{
                    this.$store.commit("GET_POSTS");
                    this.$fire({
                        title: "نجح",
                        text: res.data.msg,
                        type: "success",
                        timer: 3000
                    });
                    this.$store.state.dashboard.posts.forms.delete = false;
                }).catch(err=>{
                    console.log(err.response.data)
                }).finally(fin=>{
                    this.loading = false
                })
            }
        },
        computed:{
            get_post:function () {
                return this.$store.state.dashboard.posts.target_post;
            }
        },
        watch:{
            get_post:function (new_post) {
                this.post = new_post
            }
        },

    }
</script>

<style scoped>

</style>