<template>
    <v-container>

       <v-card>
               <ToolBar/>
           <v-divider/>
           <v-card-title>
               <v-icon>mdi-lock</v-icon>
               <span class="mr-2">المشرفين</span>
           </v-card-title>
           <v-divider/>
           <v-card-title>
               <v-btn fab color="primary" small @click="$store.state.dashboard.users.forms.add_user=true"><v-icon>mdi-plus</v-icon></v-btn>
           </v-card-title>
           <v-divider/>

           <v-card-text>
               <v-simple-table v-slot:default>
                   <thead>
                   <tr>
                       <th class="text-center">ت</th>
                       <th class="text-center">الاسم</th>
                       <th class="text-center">الحساب</th>
                       <th class="text-center">الصلاحية</th>
                       <th class="text-center">خيارات</th>
                   </tr>
                   <tr>
                       <th class="text-center"></th>
                       <th class="text-center">
                           <v-text-field @keyup="search_user" v-model="search.name" dense outlined prepend-inner-icon="mdi-magnify"/>
                       </th>
                       <th class="text-center">
                           <v-text-field @keyup="search_user" v-model="search.email" dense outlined prepend-inner-icon="mdi-magnify"/>
                       </th>
                       <th class="text-center">

                       </th>
                       <th class="text-center"></th>
                   </tr>
                   </thead>
                   <tbody>
                   <tr v-for="(item , i) in pageOfItems" :key="'user_i_'+i">
                       <td class="text-center">{{users.indexOf(item)+1}}</td>
                       <td class="text-center">{{item.name}}</td>
                       <td class="text-center">{{item.email}}</td>
                       <td class="text-center">{{item.user_level | level}}</td>
                       <td class="text-center">
                           <v-btn icon><v-icon color="primary" @click="set_user_to_edit(item)">mdi-pen</v-icon></v-btn>
                           <v-btn icon><v-icon color="error" @click="set_user_to_delete(item)">mdi-delete</v-icon></v-btn>
                       </td>
                   </tr>
                   </tbody>
               </v-simple-table>
           </v-card-text>
           <v-divider/>
           <v-card elevation="1" class="f14 text-center pa-4">
               <jw-pagination :pageSize="15" :maxPages="$vuetify.breakpoint.xs?1:10"  :items="users" :labels="customLabels" :styles="customStyles" @changePage="onChangePage"></jw-pagination>
           </v-card>
       </v-card>
        <AddUser/>
        <EditUser/>
        <DeleteUser/>
    </v-container>
</template>

<script>
    import ToolBar from "@/views/Dashboard/ToolBar";
    import JwPagination from 'jw-vue-pagination';
    import AddUser from "@/views/Dashboard/Users/AddUser";
    import EditUser from "@/views/Dashboard/Users/EditUser";
    import DeleteUser from "@/views/Dashboard/Users/DeleteUser";
    const customLabels = {
        first: 'الاول',
        last: 'الاخير',
        previous: 'السابق',
        next: 'التالي'
    };
    const customStyles = {
        ul: {
            direction:'ltr'
        },
        li: {
            display: 'inline-block',
            border:'1px solid grey',
            margin:'2px',
            borderRadius:'5px'
        },
        a: {
            color: 'black'
        }
    };
    export default {
        name: "Users",
        components:{
            ToolBar,
            JwPagination,
            AddUser,
            EditUser,
            DeleteUser
        },
        filters:{
            level:function (value) {
                if(value == 1)
                {
                    return "مدير";
                }
                if(value == 2)
                {
                    return "محرر";
                }

            }
        },
        data(){
            return{
                users:this.$store.state.dashboard.users.users,
                customLabels,
                customStyles,
                pageOfItems: [],
                search:{
                    name:'',
                    email:''
                }
            }
        },
        methods:{
            onChangePage(pageOfItems) {
                // update page of items
                this.pageOfItems = pageOfItems;
            },
            search_user(){
                var filterd = this.$store.state.dashboard.users.users;

                filterd = filterd.filter(item=>item.name.match(this.search.name));
                filterd = filterd.filter(item=>item.email.match(this.search.email));

                this.users = filterd;
            },
            set_user_to_edit(user)
            {
                this.$store.state.dashboard.users.target = user;
                this.$store.state.dashboard.users.forms.edit_user = true;
            },
            set_user_to_delete(user)
            {
                this.$store.state.dashboard.users.target = user;
                this.$store.state.dashboard.users.forms.delete_user = true;
            },

        },
        computed:{
            get_users:function () {
                return this.$store.state.dashboard.users.users;
            }
        },
        watch:{
            get_users:function (new_users) {
                this.users = new_users;
            }
        },
        created(){
            this.$store.commit("GET_USERS")
        }

    }
</script>

<style scoped>

</style>